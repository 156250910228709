<template>
<div class="row">
    <div class="col-md-12">
            
                <div class="mb-3">
                    <span class="h4 titulosg"> {{encuesta.tituloencuesta}} </span>  <br>
                    {{encuesta.descripcionencuesta}}
                </div>

                <div class="card card-body mb-3">
                    Nombre: <strong>{{persona.nombres}} {{persona.apellidos}}</strong>  
                    Identificación:  {{persona.identificacion}}
                </div>

                <div class="card p-2 mb-3">
                    <div v-for="(item, index) in preguntas" class="border-bottom p-2">
                          {{index+1}}. {{item.pregunta}}  <br>
                       <strong> {{item.respuesta}}</strong>
                        <div v-if="item.extension !== null">{{item.extension}}</div>
                    </div>
                </div>

                <div class="mt-2">
                    <div class="mb-4">
                        Firma
                    </div>
                    <div>
                        _____________________________________________ <br>
                        Nombre:<br>
                        Identificación
                    </div>
              </div>

    </div>
</div>    
</template>

<script>
import { ref, onMounted } from 'vue'
import axios from 'axios'

export default {
    props:  ['idpersona','idencuesta'],
    setup(props) {

        const encuesta = ref({})
        const preguntas = ref([])
        const persona = ref({})

        const ConsultaEncuesta = () => {
            axios.get('/api/respuestaspersona/'+props.idpersona+'/'+props.idencuesta).then( response => {
                encuesta.value = response.data.encuesta
                preguntas.value = response.data.preguntas
                persona.value = response.data.persona
            }).catch( error => { console.log(error) })
        }

        onMounted(() => {
            ConsultaEncuesta  ()
        })

        return { encuesta, preguntas, persona  }
        
    }
}
</script>

<style>

</style>
