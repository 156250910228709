<template>

    <div class="row">
        <div class="col-md-7">
            <div class="card card-body shadow-sm">
                <strong>Crear Pregunta</strong>
                <hr>

                <form @submit.prevent="guardarPregunta" id="formguardarpregunta"  name="formguardarpregunta">
                    <div class="form-row">
                        <div class="form-group col-md-2">
                            <label for="numero">Número:</label>
                            <input type="number" min="1" required class="form-control"  v-model="numero">
                        </div>

        
                        <div class="form-group col-md-10">
                            <label>Pregunta:</label>
                            <input type="text" required  name="pregunta" id="pregunta" v-model="pregunta" class="form-control">
                        </div>
                    </div>
        
        
                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label>Texto auxiliar de la pregunta:</label>
                            <textarea  id="ayuda" name="ayuda" v-model="ayuda" cols="30" rows="4"  class="form-control" maxlength="500"></textarea>
                            <small   class="form-text text-muted">
                            Máximo 500 carateres ({{ayuda.length}})
                            </small>
                        </div>
                    </div>


                    <div class="form-row">
                        <div class="form-group col-md-12">
                            <label for="">Grupo</label>
                            <select  v-model="encuestagrupo_idencuestagrupo" class="form-control">
                                <option value="">-</option>
                                <option  v-for="item in varlistagrupos" :key="item.idencuestagrupo" :value="item.idencuestagrupo"> {{item.grupo}} </option>
                            </select>
                        </div>
                    </div>
        
                    <div class="form-row">
                            
                        <div class="form-group col-md-12">
                            <label><strong>Tipo de Pregunta:</strong></label>
                            <br>

                            <div class="dropdown">
                                <button class="btn btn-outline-secondary dropdown-toggle dropright" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {{ labeltipo }}
                                </button>
                                <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                    <div  class="p-3">
                                        <strong>  Preguntas Abiertas  </strong>
                                    </div>
                                        <a class="dropdown-item" href="javascript:;" @click="tipopregunta=1; opciones=[], labeltipo='Respuesta Corta'" >
                                            <img style="width:20px" class="mr-2" src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgdmlld0JveD0iMCAwIDE2IDE2Ij4KPHBhdGggZmlsbD0iIzQ0NDQ0NCIgZD0iTTE2IDVjMC0wLjYtMC40LTEtMS0xaC0xNGMtMC42IDAtMSAwLjQtMSAxdjZjMCAwLjYgMC40IDEgMSAxaDE0YzAuNiAwIDEtMC40IDEtMXYtNnpNMTUgMTFoLTE0di02aDE0djZ6Ij48L3BhdGg+CjxwYXRoIGZpbGw9IiM0NDQ0NDQiIGQ9Ik0yIDZoMXY0aC0xdi00eiI+PC9wYXRoPgo8L3N2Zz4K" />
                                            Respuesta Corta
                                        </a>

                                        <a class="dropdown-item" href="javascript:;" @click="tipopregunta=2; opciones=[], labeltipo='Parrafo'" >
                                            <img style="width:20px" class="mr-2" src="data:image/svg+xml;base64,PHN2ZyBpZD0iSWNvbnMiIGhlaWdodD0iNTEyIiB2aWV3Qm94PSIwIDAgNzQgNzQiIHdpZHRoPSI1MTIiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTMgOGg2OHYyaC02OHoiLz48cGF0aCBkPSJtMyAxOWg2OHYyaC02OHoiLz48cGF0aCBkPSJtMyAzMGg2OHYyaC02OHoiLz48cGF0aCBkPSJtMyA0MWg2OHYyaC02OHoiLz48cGF0aCBkPSJtMyA1Mmg2OHYyaC02OHoiLz48cGF0aCBkPSJtMyA2M2g2OHYyaC02OHoiLz48L3N2Zz4=" />
                                            Párrafo 
                                        </a>
                                        
                                    <div class="dropdown-divider"></div>
                                    <div  class="p-3">
                                        <strong>  Preguntas Cerradas  </strong>
                                    </div>
                                        <a class="dropdown-item" href="javascript:;" @click="tipopregunta=3; labeltipo='Selección Única'" >
                                            <img style="width:20px" class="mr-2"  src="data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjUxMnB0IiB2aWV3Qm94PSIwIC0zNiA1MTIgNTEyIiB3aWR0aD0iNTEycHQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTEwMCAyMDBjLTU1LjE0MDYyNSAwLTEwMC00NC44NTkzNzUtMTAwLTEwMHM0NC44NTkzNzUtMTAwIDEwMC0xMDAgMTAwIDQ0Ljg1OTM3NSAxMDAgMTAwLTQ0Ljg1OTM3NSAxMDAtMTAwIDEwMHptMC0xNjBjLTMzLjA4NTkzOCAwLTYwIDI2LjkxNDA2Mi02MCA2MHMyNi45MTQwNjIgNjAgNjAgNjAgNjAtMjYuOTE0MDYyIDYwLTYwLTI2LjkxNDA2Mi02MC02MC02MHptNDEyIDQwaC0yNzJ2NDBoMjcyem0tNDEyIDBjLTExLjAyNzM0NCAwLTIwIDguOTcyNjU2LTIwIDIwczguOTcyNjU2IDIwIDIwIDIwIDIwLTguOTcyNjU2IDIwLTIwLTguOTcyNjU2LTIwLTIwLTIwem0wIDM2MGMtNTUuMTQwNjI1IDAtMTAwLTQ0Ljg1OTM3NS0xMDAtMTAwczQ0Ljg1OTM3NS0xMDAgMTAwLTEwMCAxMDAgNDQuODU5Mzc1IDEwMCAxMDAtNDQuODU5Mzc1IDEwMC0xMDAgMTAwem0wLTE2MGMtMzMuMDg1OTM4IDAtNjAgMjYuOTE0MDYyLTYwIDYwczI2LjkxNDA2MiA2MCA2MCA2MCA2MC0yNi45MTQwNjIgNjAtNjAtMjYuOTE0MDYyLTYwLTYwLTYwem00MTIgNDBoLTI3MnY0MGgyNzJ6bTAgMCIvPjwvc3ZnPg==" />
                                            Selección Única - Radio
                                        </a>

                                        <a class="dropdown-item" href="javascript:;" @click="tipopregunta=4; labeltipo='Selección Multiple'" >
                                            <img style="width:20px" class="mr-2"  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE2LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4NCjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCINCgkgd2lkdGg9IjM5LjVweCIgaGVpZ2h0PSIzOS41cHgiIHZpZXdCb3g9IjAgMCAzOS41IDM5LjUiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM5LjUgMzkuNTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPGc+DQoJPHBhdGggZD0iTTI3LjY2NywxMi4zNzhWOC40MTdINXYyMi42NjZoMjIuNjY3di05LjIxMmw1LTUuMTA4djE2LjgyYzAsMC42NjMtMC4yNjUsMS4yOTktMC43MzEsMS43Nw0KCQljLTAuNDcsMC40NjktMS4xMDQsMC43My0xLjc2OSwwLjczSDIuNWMtMS4zODEsMC0yLjUtMS4xMTktMi41LTIuNVY1LjkxN2MwLTEuMzgxLDEuMTE5LTIuNSwyLjUtMi41aDI3LjY2Nw0KCQljMS4zODEsMCwyLjUsMS4xMTksMi41LDIuNVY3LjI3bDIuNTQ3LTIuNjAyYzAuOTY2LTAuOTg2LDIuNTUtMS4wMDMsMy41MzYtMC4wMzhjMC45ODQsMC45NjYsMS4wMDMsMi41NDksMC4wMzgsMy41MzYNCgkJTDE4Ljk0OSwyOC40MzVjLTAuNDY3LDAuNDc4LTEuMTA1LDAuNzQ4LTEuNzczLDAuNzUyYy0wLjAwNCwwLTAuMDA5LDAtMC4wMTMsMGMtMC42NjMsMC0xLjI5OS0wLjI2NS0xLjc2OC0wLjczMmwtOC4xOS04LjE5MQ0KCQljLTAuOTc2LTAuOTc4LTAuOTc2LTIuNTYxLDAtMy41MzdzMi41NTktMC45NzcsMy41MzYsMGw2LjQwNCw2LjQwNUwyNy42NjcsMTIuMzc4eiIvPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPC9zdmc+DQo=" />
                                            Selección Multiple
                                        </a>
                                        <a class="dropdown-item" href="javascript:;" @click="tipopregunta=5; labeltipo='Selección Única Desplegable'" >
                                            <img style="width:20px" class="mr-2" src="data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjUxMnB0IiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im00NTIgMGgtMzkyYy0zMy4wODU5MzggMC02MCAyNi45MTQwNjItNjAgNjB2MzkyYzAgMzMuMDg1OTM4IDI2LjkxNDA2MiA2MCA2MCA2MGgzOTJjMzMuMDg1OTM4IDAgNjAtMjYuOTE0MDYyIDYwLTYwdi0zOTJjMC0zMy4wODU5MzgtMjYuOTE0MDYyLTYwLTYwLTYwem0yMCA0NTJjMCAxMS4wMjczNDQtOC45NzI2NTYgMjAtMjAgMjBoLTM5MmMtMTEuMDI3MzQ0IDAtMjAtOC45NzI2NTYtMjAtMjB2LTIwOGg0MzJ6bS00MzItMjQ4di0xNDRjMC0xMS4wMjczNDQgOC45NzI2NTYtMjAgMjAtMjBoMzkyYzExLjAyNzM0NCAwIDIwIDguOTcyNjU2IDIwIDIwdjE0NHptMzYzLjQ3NjU2Mi0xMjQuMTQ0NTMxIDI4LjI1MzkwNyAyOC4zMTI1LTY2LjU1MDc4MSA2Ni40MDIzNDMtNjcuMjI2NTYzLTY2LjMyMDMxMiAyOC4wOTM3NS0yOC40NzY1NjIgMzguOTcyNjU2IDM4LjQ0OTIxOHptMTUuNTIzNDM4IDI1NC4xNDQ1MzFoLTMyNnYtNDBoMzI2em0wIDkwaC0zMjZ2LTQwaDMyNnptMCAwIi8+PC9zdmc+" />
                                            Selección Única -  Desplegable
                                        </a>

                                <div class="dropdown-divider"></div>
                                    <div  class="p-3">
                                        <strong>  Editor Espcial  </strong>
                                    </div>
                                        <a class="dropdown-item" href="javascript:;" @click="tipopregunta=6; labeltipo='Editor de Texto'" >
                                            <img style="width:20px" class="mr-2"  src="data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjUxMnB0IiB2aWV3Qm94PSIwIDAgNTEyIDUxMiIgd2lkdGg9IjUxMnB0IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im00MjIgMzBoLTYxdjMwaDYxdjMwaDMwdjYxaDMwdi02MWgzMHYtOTBoLTkwem0zMCAwaDMwdjMwaC0zMHptMCAwIi8+PHBhdGggZD0ibTE4MCAzMGg2MHYzMGgtNjB6bTAgMCIvPjxwYXRoIGQ9Im0yNzAgMzBoNjF2MzBoLTYxem0wIDAiLz48cGF0aCBkPSJtNDUyIDI3MWgzMHY2MGgtMzB6bTAgMCIvPjxwYXRoIGQ9Im00NTIgMTgxaDMwdjYwaC0zMHptMCAwIi8+PHBhdGggZD0ibTQ4MiAzNjFoLTMwdjYxaC0zMHYzMGgtNjF2MzBoNjF2MzBoOTB2LTkwaC0zMHptMCAxMjFoLTMwdi0zMGgzMHptMCAwIi8+PHBhdGggZD0ibTMwIDI3MWgzMHY2MGgtMzB6bTAgMCIvPjxwYXRoIGQ9Im0zMCAxODFoMzB2NjBoLTMwem0wIDAiLz48cGF0aCBkPSJtMzAgMTUxaDMwdi02MWgzMHYtMzBoNjB2LTMwaC02MHYtMzBoLTkwdjkwaDMwem0wLTEyMWgzMHYzMGgtMzB6bTAgMCIvPjxwYXRoIGQ9Im05MCA0MjJoLTMwdi02MWgtMzB2NjFoLTMwdjkwaDkwdi0zMGg2MHYtMzBoLTYwem0tMzAgNjBoLTMwdi0zMGgzMHptMCAwIi8+PHBhdGggZD0ibTE4MCA0NTJoNjB2MzBoLTYwem0wIDAiLz48cGF0aCBkPSJtMjcwIDQ1Mmg2MXYzMGgtNjF6bTAgMCIvPjxwYXRoIGQ9Im0yNzAgMTIxaDE1MnYzMGgtMTUyem0wIDAiLz48cGF0aCBkPSJtMjcwIDE4MWgxNTJ2MzBoLTE1MnptMCAwIi8+PHBhdGggZD0ibTI3MCAyNDFoMTUydjMwaC0xNTJ6bTAgMCIvPjxwYXRoIGQ9Im05MCAzMDFoMzMydjMwaC0zMzJ6bTAgMCIvPjxwYXRoIGQ9Im05MCAzNjFoMzMydjMwaC0zMzJ6bTAgMCIvPjxwYXRoIGQ9Im05MCAxODFoMzB2LTMwaDMwdjkwaC0zMHYzMGg5MHYtMzBoLTMwdi05MGgzMHYzMGgzMHYtNjBoLTE1MHptMCAwIi8+PC9zdmc+" />
                                             Editor de Texto 
                                        </a>

                                </div>
                            </div>
                            
                            <input type="hidden" required id="tipopregunta" v-model="tipopregunta">
                        </div>
                    </div>
        

                    <div class="form-row mt-3 mb-3" v-if="tipopregunta > 2">

                        <strong>Agregar Opción de Respuesta:</strong>
                        <div class="input-group mb-3 mt-2">
                            <input  v-model="datoopcion" type="text" class="form-control" placeholder="Descripción   Ej: Respuesta 1"  >
                            <div class="input-group-append">
                            <button class="btn btn-sm btn-outline-success" type="button" id="button-addon2"  @click="agregaropcion" >+ Agregar</button>
                            </div>
                        </div>

                        <div class="col-md-12 card bg-light">

                                <div v-for="(item, index) in opciones" :key="index"  class="p-2 border-bottom">
                                    
                                    <button class="btn btn-sm btn-light float-left mr-3" type="button" title="Eliminar"  @click="quitaropcion(index)">
                                        <i class="fas fa-times"></i>
                                    </button>   {{item}} 
                                </div>

                        </div>

                        <!-- Input de opciones -->
                        <input type="hidden" v-model="opciones">
                    </div>
        
        
                    <div class="form-row mt-2">
                        <div class="form-group col-md-12">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="obligatorio" v-model="obligatoria" value="1"> 
                                <label class="custom-control-label" for="obligatorio">Obligatorio</label>
                            </div>

                        </div>
                    </div>

                    <div class="form-row mt-2">
                        <div class="form-group col-md-12">
                            <label for=""> 
                                <a href="javascript:;" @click="anadir=true">Añadir respuesta abierta "Otra cual?":</a>
                            </label>
                            <div class="input-group mb-3 mt-2"  v-if="anadir" >
                                <input  type="text" class="form-control" v-model="extender" placeholder="Descripción:  Ej: Cual?.. Otro..." >
                                <div class="input-group-append">
                                <button class="btn btn-sm btn-outline-danger" type="button"  title="Cancelar" @click="anadir=false; extender=''" ><i class="fas fa-times"></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
        


                    <div class="mt-3 border-top pt-3">

                        <button type="submit" :disabled="enviar" class="btn btn-sm btn-primary mr-2">

                            <div v-if="enviar" class="spinner-border spinner-border-sm mr-3" role="status">
                                <span class="sr-only">cargando...</span>
                            </div>
                            <i class="fas fa-save"></i> Guardar y continuar
                        </button>
                        
                        <button type="button" class="btn btn-sm btn-outline-secondary" @click="$router.push({ name: 'infoencuesta', params: { 'id': idencuestaget} })" >Cancelar</button>
                    </div>


                    
                </form>
            </div>

        </div>

        <div class="col-md-5">


            <div class="text-secondary mb-3">Vista previa de la pregunta</div>

            <strong> {{numero}}. {{pregunta}}  <span class="text-danger" v-if="obligatoria">*</span> </strong>
            <div class="text-secondary mb-3"> {{ayuda}} </div>

                <label for="">Respuesta:</label>
                <input v-if="tipopregunta === 1" type="text" class="form-control mt-2">

                <textarea v-if="tipopregunta === 2" class="form-control mt-2"  cols="30" rows="3"></textarea>
                <textarea v-if="tipopregunta === 6" class="form-control mt-2"  cols="30" rows="3"></textarea>

                <div class="form-check" v-if="tipopregunta === 3" value="" v-for="(item, index) in opciones" :key="index">
                    <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked>
                    <label class="form-check-label" for="exampleRadios1">
                      {{item}}
                    </label>
                  </div>

                <div class="form-check" v-if="tipopregunta === 4" value="" v-for="(item, index) in opciones" :key="index"> 
                    <input class="form-check-input" type="checkbox" value="" id="defaultCheck1">
                    <label class="form-check-label" for="defaultCheck1">
                      {{item}}
                    </label>
                </div>

                <select  v-if="tipopregunta === 5" class="form-control">
                    <option value="" v-for="(item, index) in opciones" :key="index">{{item}}</option>
                </select>
   
                

             <!--  -->
             <div class="mt-3" v-if="extender !== ''" >
                 <label class="mt-2">{{extender}}</label>
                 <input  type="text" class="form-control">
            </div>


        </div>
    </div>

</template>
<script>

    import axios from 'axios'
    import { ref, onMounted } from 'vue'
    
    export default {
        props:['id'],
        setup(props){
            
            const idencuestaget = ref(props.id)

            const numero = ref('')
            const pregunta = ref('')
            const tipopregunta = ref('')
            const encuestagrupo_idencuestagrupo = ref('')
            const obligatoria = ref(false)
            const ayuda = ref('')
            const extender = ref('')
            const estadopregunta = ref('')


            const datoopcion = ref('');
            const opciones = ref([]);

            const labeltipo = ref('Selecctione el Tipo de Pregunta')
            const anadir = ref(false)

            const varlistagrupos = ref({}) // almacena los grupos

            const estado = ref(true)
            
            const enviar = ref(false) // caargando en el boton de enviar

                const guardarPregunta = () => {

                    enviar.value = true

                   let form = {
                        "numero" : numero.value,  
                        "pregunta" : pregunta.value,  
                        "tipopregunta" : tipopregunta.value,  
                        "encuestagrupo_idencuestagrupo" : encuestagrupo_idencuestagrupo.value,  
                        "obligatoria" : obligatoria.value,  
                        "ayuda" : ayuda.value,  
                        "extender" : extender.value,  
                        "estadopregunta" : estadopregunta.value,  
                    }

                    const url = "api/guardarpregunta/"
                    axios.post(url, {
                                        datosencuesta: form,
                                        opciones: opciones.value,
                                        idencuesta: props.id
                                    })
                                    .then(function (response) {
                                        

                                        swal("Bien!", "Información Registrada Correctamente!", "success");

                                        //Restaurar valores
                                        opciones.value = []
                                        labeltipo.value =  'Selecctione el Tipo de Pregunta' 
                                        datoopcion.value = '' 
                                        anadir.value = false
                                        numero.value = ''
                                        pregunta.value = ''
                                        tipopregunta.value = ''
                                        encuestagrupo_idencuestagrupo.value = ''
                                        obligatoria.value = false
                                        ayuda.value = ''
                                        extender.value = ''
                                        estadopregunta.value = ''

                                        enviar.value = false

                                    })
                                    .catch(function (error) {
                                        console.log(error);
                                    });
                   
                }

                const listarGrupos = () => {
                    const url = "api/gruposxencuesta/"+props.id
                    
                    axios.get(url).then( response => {

                        varlistagrupos.value = response.data; 

                    }).catch(error => {

                        console.log(error)
                    })
                }

                const agregaropcion = () => {
                    opciones.value.push(datoopcion.value);
                    datoopcion.value = ''
                }
                const quitaropcion = (index) => {
                    opciones.value.splice(index, 1);
                }
    
                
                onMounted(() => {
                    
                listarGrupos();

                })
     
            // Return
            return { 

                    idencuestaget,

                    numero,
                    pregunta,
                    tipopregunta,
                    encuestagrupo_idencuestagrupo,
                    obligatoria,
                    ayuda,
                    extender,
                    estadopregunta,
 
                    labeltipo,
                    guardarPregunta,
                    estado,
                    anadir,
                    datoopcion,
                    opciones,
                    agregaropcion,
                    quitaropcion,
                    varlistagrupos,
                    listarGrupos,
                    enviar
                }
    
        }
    
    }
    </script>
    